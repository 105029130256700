import { getAxiosObject } from '@/utils/library'
import { usedMailMessage } from '@/utils/validation_rules'

export default {
  data() {
    return {
      mixinIsNgEmail: false,
      mixinUsedMailMessage: '',
      mixinTimeoutId: null,
      mixinDelay: 300
    }
  },

  beforeDestroy() {
    clearTimeout(this.mixinTimeoutId)
  },

  methods: {
    async mixinKeyupIsOkEmail(errors, email) {
      clearTimeout(this.mixinTimeoutId)
      this.mixinTimeoutId = setTimeout(async () => {
        if (errors.length === 0 && email !== '') {
          const result = await this.mixinFetchIsOkEmail(email)
          if (
            result?.uniqueEmail ||
            result === 'not authenticated' ||
            result === false
          ) {
            //ログイン期限が切れている・またはサーバーエラーの場合、次へボタンを押した時にエラーを表示して知らせる
            this.mixinIsNgEmail = false
            this.mixinUsedMailMessage = ''
          } else if (result?.uniqueEmail === false) {
            this.mixinIsNgEmail = true
            this.mixinUsedMailMessage = usedMailMessage
          }
        } else {
          this.mixinIsNgEmail = false
          this.mixinUsedMailMessage = ''
        }
      }, this.mixinDelay)
    },
    async mixinFetchIsOkEmail(email) {
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/owners/email', {
          params: { email }
        })
        return { uniqueEmail: res.data.uniqueEmail }
      } catch (ex) {
        return ex.response?.status === 401 ? 'not authenticated' : false
      }
    }
  }
}
